import React, { useContext } from "react";
import styled from "styled-components";
import { THEME, deviceWidth, globalPadding } from "../../../config";
import bg from "../../../assets/background.png";
import crest from "../../../assets/coolcatsCrest.png";
import logo from "../../../assets/CCxOF_LogoBlack.png";
import cat from "../../../assets/OneFootballCat.png";
import { socialContentData } from "../../../constants";
import Button from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { MetaMaskContext } from "../../../hoc/Metamask";
import { Popconfirm } from "antd";
import { limitCharacter } from "../../../helpers/limitCharacter";
import { useMediaQuery } from "react-responsive";

interface IHomepageTopSection {
  subText?: string;
  sideImageSrc?: string;
  mintPage?: boolean;
}

interface TitleProps {
  readonly isMintPage: boolean;
}

const HomePageTopSection: React.FC<IHomepageTopSection> = ({
  subText,
  sideImageSrc,
  mintPage,
}) => {
  const navigate = useNavigate();
  const { connect, isActive, account, disconnect } =
    useContext(MetaMaskContext);

  const isResponsive = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <SectionWrapper isMintPage={mintPage as boolean}>
      <div className="firstCol">
        <Link to={"/"}>
          <img src={crest} alt="Crest" className="crest" />
        </Link>

        <div>
          <img src={logo} alt="Coolcats x Onefootball logo" className="logo" />
        </div>

        <p className="subtext">{subText || "LAUNCHES NOVEMBER 17, 2022"}</p>

        <div className="top-social">
          {socialContentData.map(({ Icon, title, link }, idx) => (
            <a
              key={`social_${idx}`}
              href={link}
              target="_blank"
              rel="noreferrer"
              className={title}
            >
              <Icon className="icon-social" />
            </a>
          ))}
        </div>

        <div className="firsCol-container">
          {!mintPage && (
            <Button
              btntext="MINT NOW!"
              className="btn-style"
              onClick={() => {
                navigate("/ccfc-mint");
              }}
              btntype="primary"
              btnSize={isResponsive ? "medium" : "large"}
            />
          )}
        </div>
      </div>

      {mintPage ? (
        <div className="main-connect">
          {isActive ? (
            <Popconfirm
              overlayClassName={"wallet-disconnect-popover"}
              okText={"Yes"}
              className="popover"
              onConfirm={disconnect}
              title={"Disconnect Wallet?"}
            >
              <Button
                btntext={`${limitCharacter(account || "", 5, true)}`}
                className="btn-style btn-style-success"
                btntype="neutral"
                btnSize={isResponsive ? "medium" : "large"}
              />
            </Popconfirm>
          ) : (
            <Button
              btntext="CONNECT WALLET"
              className="btn-style"
              onClick={() => {
                connect && connect();
              }}
              btntype="primary"
              btnSize="large"
            />
          )}
        </div>
      ) : (
        <></>
      )}

      <div className={mintPage ? "secondColMint" : "secondCol"}>
        {mintPage ? (
          <img
            src={sideImageSrc}
            alt="One football card pack"
            className="pack"
          />
        ) : (
          <img src={cat} alt="One football cat" className="cat" />
        )}
      </div>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div<TitleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0e5;

  @media screen and (max-width: ${deviceWidth.tablet}) {
    display: flex;
    flex-direction: column;
  }

  // @ts-ignore
  background-image: ${(props) => (props.isMintPage ? `` : `url(${bg})`)};
  width: 100%;
  /* height: 600px; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 48px;
  padding-left: ${globalPadding.large};
  padding-right: ${globalPadding.large};

  @media screen and (max-width: ${deviceWidth.desktopMd}) {
    padding-left: ${globalPadding.medium};
    padding-right: ${globalPadding.medium};
  }

  @media screen and (max-width: ${deviceWidth.desktopXs}) {
    padding-left: ${globalPadding.small};
    padding-right: ${globalPadding.small};
  }

  @media screen and (max-width: ${deviceWidth.tabletXl}) {
    padding-left: ${globalPadding.xsmall};
    padding-right: ${globalPadding.xsmall};
  }

  @media screen and (max-width: ${deviceWidth.tablet}) {
    padding-left: ${globalPadding.mobile};
    padding-right: ${globalPadding.mobile};
    text-align: center;
  }
  padding-bottom: 24px;
  gap: 28px;

  @media screen and (max-width: ${deviceWidth.mobile768}) {
    gap: 0px;
  }

  & .btn-style {
    margin-top: 24px;
  }

  & .logo {
    width: 475px;
    height: auto;

    @media screen and (max-width: ${deviceWidth.mobile768}) {
      width: 272px;
      height: auto;
    }
  }

  & .cat {
    @media screen and (max-width: ${deviceWidth.mobile768}) {
      width: 178px;
      height: auto;
    }
  }

  & .pack {
    display: block;
    @media screen and (max-width: ${deviceWidth.tablet}) {
      display: none;
    }
    @media screen and (max-width: ${deviceWidth.mobile768}) {
      width: 178px;
      height: auto;
      display: none;
    }
  }

  & .main-connect {
    display: none;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      display: block;
      margin-bottom: 32px;
    }
  }

  & .secondCol {
    align-self: flex-end;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;

    z-index: 1;
    margin-bottom: -48px;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      justify-self: center;
      align-self: center;
      gap: 40px;
    }

    @media screen and (max-width: ${deviceWidth.mobile768}) {
      margin-bottom: -34px;
      gap: 40px;
    }
  }

  & .secondColMint {
    @media screen and (max-width: ${deviceWidth.tablet}) {
      justify-self: center;
      align-self: center;

      &.cat {
        display: none !important;
      }
    }
  }

  & .firstCol {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .firstCol-container {
      display: flex;
    }

    @media screen and (max-width: ${deviceWidth.tablet}) {
      justify-self: center;
      align-self: center;
      padding-bottom: 22px;
      align-items: center;
    }
  }

  & .crest {
    @media screen and (max-width: ${deviceWidth.mobile768}) {
      width: 72px;
      height: auto;
    }
  }

  & .header {
    font-family: "Fixture";
    font-style: normal;
    font-weight: 600;
    font-size: 128px;
    line-height: 154px;
    white-space: nowrap;
    color: #000000;
  }

  & .subtext {
    font-family: "Fixture";
    font-style: italic;
    font-weight: 600;
    font-size: 48px;
    line-height: 32px;
    line-height: 46px;
    white-space: pre-line;
    text-align: left;
    min-height: 46px;

    @media screen and (max-width: ${deviceWidth.mobile768}) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & .infotext {
    font-family: "Fixture";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    margin-top: 22px;
    font-size: 48px;
    line-height: 24px;

    color: #000000;
    display: inline-block;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      width: 100%;
    }

    p {
      margin: 0px;
      padding: 0px;
    }

    img {
      width: 100%;
      object-fit: cover;
      @media screen and (max-width: ${deviceWidth.tablet}) {
        width: 50%;
      }
    }
  }
  & .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 24px;
    & .icon-social {
      display: block;
      margin: auto;
    }
  }

  & .top-social {
    display: none;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;
      padding-top: 12px;
      justify-content: center;
      padding-top: 28px;
    }
  }
`;

export default HomePageTopSection;
