import Button from "../../../components/atoms/Button";
import PlusIcon from "./PlusIcon";
import MinusIcon from "./MinusIcon";
import { Modal, notification } from "antd";
import { deviceWidth, THEME } from "../../../config";
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { formatMetamaskError } from "../../../helpers/formatters";
import { MetaMaskContext } from "../../../hoc/Metamask";
import { generateCrowdsaleContract } from "../../../services/crowdSaleContract";
import ChallengeQuestion from "./ChallengeQuestion";
import { addUserAnswer } from "../../../services/firebase";
import { EnumCrowdSalePhase } from "../../../config/chain";
import mintCardImg from "../../../assets/CardPackMint.png";

interface IMintLiveProps {
  totalCap: number;
  totalMinted: number;
  mintPrice: number;
  refetchOnSuccess: () => void;
  phase: number;
}

const MintLive: React.FC<IMintLiveProps> = ({
  totalCap,
  totalMinted,
  mintPrice,
  refetchOnSuccess,
  phase,
}) => {
  const MINT_LIMIT = phase === EnumCrowdSalePhase.SALE_PHASE_2 ? 1 : 2;

  const [mintCount, setmintCount] = React.useState(0);

  const [isMinting, setIsMinting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [answer, setAnswer] = useState("");
  const [userPurchaseCount, setUserPurchaseCount] = useState(0);
  const [userWhiteListed, setWhiteListed] = useState(true);

  const [refresh, resetFormValues] = useState(0);

  const { connect, isActive, account } = useContext(MetaMaskContext);
  const [visible, setVisible] = useState(false);

  const publicSaleContract = React.useMemo(() => {
    return generateCrowdsaleContract();
  }, []);

  const fetchUserPurchaseCount = async (walletAddress: string) => {
    try {
      if (phase === EnumCrowdSalePhase.SALE_PHASE_4) return 0;
      const count = Number(
        await publicSaleContract.getUserPurchasedCount(walletAddress)
      );
      setUserPurchaseCount(count);
    } catch (e) {
      console.log({ e });
    }
  };

  let [COUNTER_LIMIT, setCounterLimit] = useState(
    MINT_LIMIT - userPurchaseCount
  );

  useEffect(() => {
    setCounterLimit(MINT_LIMIT - userPurchaseCount);
  }, [userPurchaseCount, MINT_LIMIT]);

  console.log({ MINT_LIMIT, userPurchaseCount, COUNTER_LIMIT });

  const fetchWhiteListed = (walletAddress: string) => {
    const whiteListed =
      publicSaleContract.getIsWalletWhitelisted(walletAddress);
    setWhiteListed(whiteListed);
  };

  const isAboveLimit = () => {
    return (
      phase !== EnumCrowdSalePhase.SALE_PHASE_4 &&
      mintCount > MINT_LIMIT - userPurchaseCount
    );
  };

  useEffect(() => {
    if (account) {
      fetchUserPurchaseCount(account);
      fetchWhiteListed(account);
    }
  }, [account, refresh]);

  const handleMint = async () => {
    if (!mintCount) {
      notification.error({
        message: "Mint Quantity must be at least 1!",
      });
      return;
    }

    if (!account) {
      notification.error({
        message: "Please connect with your wallet first!",
      });
      return;
    }

    if (!isValid) {
      notification.error({
        message: "Please accept all the conditions",
      });
      return;
    }

    setIsMinting(true);
    try {
      const txn = await publicSaleContract.mintTokens(
        account ?? "",
        mintPrice,
        mintCount
      );

      try {
        // Save to db
        console.log("Saving to database");
        const doc = await addUserAnswer({
          walletAddress: account ?? "",
          answer,
        });
        console.log("Saved successfully", doc?.id);
      } catch (e) {
        console.error("Error saving to db ", e);
      }
      // notification.success({ message: "Successfully Minted!" });
      setVisible(true);

      refetchOnSuccess();
      resetFormValues((prev) => prev + 1);
      console.log("Mint Txn", txn);
    } catch (error: any) {
      console.log({ error });
      let errorMessage = "Error while minting!";
      if (error.code === "MINT_VALIDATION_FAILED") {
        errorMessage = error.message;
      }

      notification.error({ message: formatMetamaskError(error, errorMessage) });
    } finally {
      setIsMinting(false);
    }
  };

  return isActive ? (
    <div className="minting-container">
      <ChallengeQuestion
        handler={(isValid: boolean, answer: string) => {
          setIsValid(isValid);
          setAnswer(answer);
        }}
        refresh={refresh}
      />

      {/* <div className="card-header">Start mint</div>
      <p className="card-body">
        <p>Total Cap : {totalCap}</p>
        <p>Total Minted : {totalMinted}</p>
        Select the number of NFTs you want to mint.
      </p> */}

      <BottomContainer>
        {visible && (
          <div className="modal">
            <p>LFGOOOOAAAALLLLLL!</p>
            <img src={mintCardImg} alt="Mint card"></img>
            <p>YOU HAVE SUCCESSFULLY MINTED {mintCount} CCFC NFT!!!</p>
            {/* <p>SERIAL # XXXX </p>
            <p>SERIAL # YYYY</p> */}
            <br></br>
            <Button
              btntext="CLOSE"
              className="btn-style"
              onClick={() => {
                setVisible(false);
              }}
              btntype="secondary"
              notRounded
            />
          </div>
        )}
        <div
          className="mint-container"
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {mintCount <= 1 ? (
              // <DButton className="mint-btn disabled">
              //   <MinusIcon fill={`${THEME.colors.contentDisabled}`} />
              // </DButton>
              <p className="mint-btn" style={{ cursor: "pointer" }}>
                <MinusIcon fill={`${THEME.colors.brand}`} />
              </p>
            ) : (
              <p
                className="mint-btn"
                onClick={() => setmintCount(mintCount - 1)}
                style={{ cursor: "pointer" }}
              >
                <MinusIcon fill={`${THEME.colors.brand}`} />
              </p>
            )}
          </div>

          <div
            className="mint-input"
            style={{
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: "bold",
              minWidth: "16px",
            }}
          >
            {mintCount}
          </div>
          <div>
            {mintCount >= COUNTER_LIMIT ? (
              // <DButton className="mint-btn disabled">
              //   <PlusIcon fill={`${THEME.colors.contentDisabled}`} />
              // </DButton>
              <p className="mint-btn" style={{ cursor: "pointer" }}>
                <PlusIcon fill={`${THEME.colors.brand}`} />
              </p>
            ) : (
              <p
                className="mint-btn"
                onClick={() => setmintCount(mintCount + 1)}
                style={{ cursor: "pointer" }}
              >
                <PlusIcon fill={`${THEME.colors.brand}`} />
              </p>
            )}
          </div>
        </div>
        {/* <div className="mint-info">
          <p>* Maximum 2 per transaction</p>
        </div> */}
        <div className="connect-wallet" style={{ flexGrow: 1 }}>
          <LButton
            onClick={handleMint}
            btnSize="large"
            btntype="primary"
            btntext={
              isMinting ? "MINTING..." : "MINT"
              //   : `Mint at ${formatWei(mintPrice * mintCount)} ETH`
            }
            isdisabled={
              isMinting ||
              !isValid ||
              !userWhiteListed ||
              mintCount === 0 ||
              isAboveLimit()
            }
          />

          {!userWhiteListed && (
            <p className="errorText">
              Sorry, you're not on this allowlist! Please check back in the next window or come back for the public mint Nov 19, 10AM ET
            </p>
          )}
          {/* {isAboveLimit() && (
            <p className="errorText">
              You have already minted {userPurchaseCount} time(s). Limit ={" "}
              {MINT_LIMIT}
            </p>
          )} */}
        </div>
      </BottomContainer>
    </div>
  ) : (
    <LButton
      onClick={connect}
      btnSize="large"
      btntype="primary"
      btntext="CONNECT WALLET"
    />
  );
};

export default MintLive;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  .modal {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    font-size: 42px;
    font-style: italic;
    line-height: 58px;
    text-align: center;
    color: ${THEME.colors.brand};
    font-family: "Fixture";
  }

  .errorText {
    color: red;
    padding: 12px;
  }

  @media screen and (max-width: ${deviceWidth.mobile768}) {
    flex-direction: column;
    justify-content: center;
    align-items: initial;
    padding: 0px 30px;
  }
`;

const LButton = styled(Button)`
  /* ${THEME.typography.label4} */
  color: ${THEME.colors.primary};
  border-radius: 0px;

  width: 100%;
  /* font-family: "Fixture"; */
  font-weight: 600;
  font-size: 24px;
  /* padding-bottom: 24px; */
  /* margin-top: 32px; */
`;
// disabled button
const DButton = styled.p`
  ${THEME.typography.label4}

  border-radius: 100px;
  color: ${THEME.colors.contentMuted};
  &:hover {
    color: ${THEME.colors.contentMuted};
  }
  &:focus {
    color: ${THEME.colors.contentMuted};
  }
  &:active {
    color: ${THEME.colors.contentMuted};
  }
`;
