import styled from "styled-components";
import { deviceWidth } from "../../../config";
import { footerIcons, socialContentData } from "../../../constants";
import bg from "../../../assets/backgroundFooter.png";
import poweredBy from "../../../assets/footer/poweredBy.png";

export const Footer = () => {
  return (
    <Wrapper>
      <div className="footer-icon-wrapper">
        <img
          loading="lazy"
          srcSet={poweredBy + " 2x"}
          alt="starter"
          className="icon"
        ></img>
        {footerIcons.map(({ Icon, link }, idx) => (
          <div className="icon" key={`footer_${idx}`}>
            {link ? (
              <a
                key={`footer_${idx}`}
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                <Icon />
              </a>
            ) : (
              <Icon key={`footer_${idx}`} />
            )}
          </div>
        ))}
      </div>

      <div className="footer-social">
        {socialContentData.map(({ Icon, title, link }, idx) => (
          <a
            key={`social_${idx}`}
            href={link}
            target="_blank"
            rel="noreferrer"
            className={title}
          >
            <Icon className="icon-social" />
          </a>
        ))}
      </div>
      <p className="copyright">Ⓒ 2022 COOL CATS GROUP LLC</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f0f0e5;

  /* background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */

  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  & .footer-icon-wrapper {
    margin-top: 88px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      margin-top: 100px;
      flex-direction: column;
    }
  }

  & .footer-social {
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding-top: 24px;
    align-items: center;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      display: none;
    }
  }

  & .icon {
    padding-right: 32px;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      padding-bottom: 32px;
      padding-right: 0px;
      text-align: center;
    }
  }

  & .copyright {
    padding-top: 88px;
    padding-bottom: 42px;
  }
`;
