/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Web3 from "web3";
import { getChainInfo } from "../config/chain";
import switchChainInMetamask from "../helpers/switchChain";
import { Contract } from "web3-eth-contract";
import { AbiItem } from "web3-utils";

declare let window: any;

export class ContractService {
    protected web3: Web3;
    protected writeContract: Contract;
    private readWeb3: Web3;
    protected readContract: Contract;
    public address: string;

    constructor(contractAddress: string, abi: any) {
        const { rpcUrls } = getChainInfo();
        this.web3 = new Web3(window.ethereum);
        this.writeContract = new this.web3.eth.Contract(abi, contractAddress);

        this.readWeb3 = new Web3(rpcUrls[0]);
        this.readContract = new this.readWeb3.eth.Contract(
            abi,
            contractAddress
        );
        this.address = contractAddress;
    }

    // To check if the metamask if connected with different network
    protected validateChainAndContinue = async (next: any) => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            const { chainId } = getChainInfo();
            const web3 = new Web3(window.ethereum);
            const connectedChainId = await web3.eth.getChainId();
            if (Number(chainId) === Number(connectedChainId)) {
                return await next(); //Since the contract and the metamask are in same network, we can proceed
            } else {
                //If the contract and the metamask are in different network, first switch the metmask network and then call the contract
                const isSwitched = await switchChainInMetamask();
                if (isSwitched) return await next();
                else throw Error("Error while switching the network");
            }
        } else throw Error("Metamask Provider couldn't be detected!");
    };
}
