/* eslint-disable no-throw-literal */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Web3 from "web3";
import {
    ContractType,
    EnumCrowdSalePhase,
    getContractAddress,
} from "../config/chain";
import { ContractService } from "./contract";
import { AbiItem } from "web3-utils";
import { getCrowdSaleDetails } from "../config/sale";

export class CrowdSaleContractService extends ContractService {
    private merkelProofJSON: any;
    private phase: number;

    constructor(
        contractAddress: string,
        abi: AbiItem,
        phase: number = EnumCrowdSalePhase.SALE_PHASE_4,
        merkelProof: Array<any>
    ) {
        super(contractAddress, abi);
        this.phase = phase;
        this.merkelProofJSON = merkelProof;
    }

    //tocheck if the sale is paused
    public getTokenPrice = async () => {
        const price = await this.readContract.methods.price().call();
        return price;
    };

    public getPauseStatus = async () => {
        const status = await this.readContract.methods.paused().call();
        return status;
    };
    public getClosingTime = async () => {
        const closingTime = await this.readContract.methods
            .closingTime()
            .call();
        return closingTime;
    };

    public getOpeningTime = async () => {
        const closingTime = await this.readContract.methods
            .openingTime()
            .call();
        return closingTime;
    };

    public getTokenCap = async () => {
        const tokenCap = await this.readContract.methods.cap().call();
        return tokenCap;
    };

    public getTokenSold = async () => {
        const tokenSold = await this.readContract.methods.tokenSold().call();
        return tokenSold;
    };

    public getUserPurchasedCount = async (walletAddress: string) => {
        const userPurchaseCount = await this.readContract.methods
            .purchase(walletAddress)
            .call();
        return userPurchaseCount;
    };

    public getPurchaseLimit = async () => {
        const purchaseLimit = await this.readContract.methods
            .purchaseLimit()
            .call();
        return purchaseLimit;
    };

    public getIsWalletWhitelisted = (walletAddress: string) => {
        if (
            this.phase === EnumCrowdSalePhase.SALE_PHASE_1 ||
            this.phase === EnumCrowdSalePhase.SALE_PHASE_2 ||
            this.phase === EnumCrowdSalePhase.SALE_PHASE_3
        ) {
            const found = this.merkelProofJSON.find(
                (item: any) =>
                    item.address.toLowerCase() === walletAddress.toLowerCase()
            );
            if (found) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    public getUniqueID = () => {
        const account = this.web3.eth.accounts.create();
        return account;
    };

    public getTokensByUniqueID = async (uid: string) => {
        const tokens = await this.readContract.methods
            .getTokensByUID(uid)
            .call();
        return tokens;
    };

    private validateMintData = async (
        walletAddress: string,
        mintTokenPrice: number,
        quantity: number
    ) => {
        const userBalance = await this.web3.eth.getBalance(walletAddress);

        if (this.phase === EnumCrowdSalePhase.NOT_STARTED) {
            throw {
                message: "Sale has not started yet!",
                code: "MINT_VALIDATION_FAILED",
            };
        }

        if (this.phase === EnumCrowdSalePhase.PRESALE_OVER) {
            throw {
                message: "Presale Over. Public sale will open soon!",
                code: "MINT_VALIDATION_FAILED",
            };
        }

        if (Number(userBalance) < mintTokenPrice * quantity) {
            throw {
                message: "Insufficient Balance!",
                code: "MINT_VALIDATION_FAILED",
            };
        }

        const isPaused = await this.getPauseStatus();
        if (isPaused) {
            throw {
                message: "CrowdSale is Paused!",
                code: "MINT_VALIDATION_FAILED",
            };
        }

        return true;
    };

    // Mint Tokens

    public mintTokens = async (
        walletAddress: string,
        mintTokenPrice: number,
        quantity: number
    ) => {
        // console.log(price);



        const totalTokenPrice = mintTokenPrice * quantity;
        if (
            this.phase === EnumCrowdSalePhase.SALE_PHASE_1 ||
            this.phase === EnumCrowdSalePhase.SALE_PHASE_2 ||
            this.phase === EnumCrowdSalePhase.SALE_PHASE_3
        ) {
            const merketProof = this.merkelProofJSON.find(
                (item: any) =>
                    item.address.toLowerCase() === walletAddress.toLowerCase()
            );

            console.log({ merketProof });

            return await this.validateChainAndContinue(async () => {
                try {
                    await this.validateMintData(walletAddress, mintTokenPrice, quantity);
                    const call = this.writeContract.methods.buyTokensPresale(
                        merketProof?.merkleProof ?? []
                    );
                    const estimatedGasFee = await call.estimateGas({
                        from: walletAddress,
                        value: totalTokenPrice,
                    });
                    const gasPrice = await this.web3.eth.getGasPrice()
                    const gasPriceBN = Web3.utils.toBN(gasPrice);
                    const finalGasPrice = gasPriceBN
                        .add(this.web3.utils.toBN(parseInt(String(0.2 * gasPriceBN.toNumber()))))
                        .toString();
                    console.log({ gasPrice, gasPriceBN, finalGasPrice })

                    await call.send({
                        from: walletAddress,
                        value: totalTokenPrice,
                        gasLimit: Math.ceil(estimatedGasFee * 1.2),
                        gasPrice: finalGasPrice
                    });
                } catch (error) {
                    console.log("Error", error);
                    throw error;
                }
            });
        }

        return await this.validateChainAndContinue(async () => {
            try {
                await this.validateMintData(walletAddress, mintTokenPrice, quantity);
                const call = this.writeContract.methods.buyTokensPublicsale();

                const estimatedGasFee = await call.estimateGas({
                    from: walletAddress,
                    value: totalTokenPrice,
                });
                const gasPrice = await this.web3.eth.getGasPrice()
                const gasPriceBN = Web3.utils.toBN(gasPrice);
                const finalGasPrice = gasPriceBN
                    .add(this.web3.utils.toBN(parseInt(String(0.2 * gasPriceBN.toNumber()))))
                    .toString();
                console.log({ gasPrice, gasPriceBN, finalGasPrice })


                await call.send({
                    from: walletAddress,
                    value: totalTokenPrice,
                    gasLimit: Math.ceil(estimatedGasFee * 1.2),
                    gasPrice: finalGasPrice
                });
            } catch (error) {
                console.log("Error", error);
                throw error;
            }
        });
    };
}

/* Creating a new instance of the CrowdSaleContractService class. */
export const generateCrowdsaleContract = () => {
    const crowdSaleDetails = getCrowdSaleDetails();
    const crowdSaleContractConfig = getCrowdSaleContractInfo(
        crowdSaleDetails.phase
    );



    return new CrowdSaleContractService(
        crowdSaleContractConfig.address,
        crowdSaleContractConfig.abi as any,
        crowdSaleDetails.phase,
        // @ts-ignore
        crowdSaleDetails.proof
    );
};

const getCrowdSaleContractInfo = (phase: number) => {
    switch (phase) {
        case EnumCrowdSalePhase.NOT_STARTED:
            return getContractAddress(ContractType.SALE_PHASE_1);
        case EnumCrowdSalePhase.SALE_PHASE_1:
            return getContractAddress(ContractType.SALE_PHASE_1);
        case EnumCrowdSalePhase.SALE_PHASE_2:
            return getContractAddress(ContractType.SALE_PHASE_2);
        case EnumCrowdSalePhase.SALE_PHASE_3:
            return getContractAddress(ContractType.SALE_PHASE_3);
        case EnumCrowdSalePhase.PRESALE_OVER:
            return getContractAddress(ContractType.SALE_PHASE_4);
        case EnumCrowdSalePhase.SALE_PHASE_4:
            return getContractAddress(ContractType.SALE_PHASE_4);
        default:
            return getContractAddress(ContractType.SALE_PHASE_4);
    }
};
