import React from "react";
import styled from "styled-components";
import { infoIcons } from "../../../constants";
import sample from "../../../assets/sample.png";
import starter from "../../../assets/CCFC_1Starter.png";
import pro from "../../../assets/CCFC_2Pro.png";
import bronze from "../../../assets/CCFC_3Bronze.png";
import silver from "../../../assets/CCFC_4Silver.png";
import gold from "../../../assets/CCFC_5Gold.png";
import diamond from "../../../assets/CCFC_6Diamond.png";

import { deviceWidth, globalPadding } from "../../../config";
import Rewards from "./Rewards";
import HoldersJourney from "./HoldersJourney";
import poweredBy from "../../../assets/footer/poweredBy-light.png";

const gridItems = [
  { text: "GROUP STAGE", img: starter },
  { text: "ROUND OF 16", img: pro },
  { text: "QUARTERFINALS", img: bronze },
  { text: "SEMIFINALS", img: silver },
  { text: "FINALS", img: gold },
  { text: "CHAMPIONS", img: diamond },
];

export default function HomePageInfoSection() {
  return (
    <SectionWrapper>
      <div className="icon-wrapper">
        <img
          loading="lazy"
          srcSet={poweredBy + " 2x"}
          alt="starter"
          className="icon"
        ></img>
        {infoIcons.map(({ Icon, link }, idx) => (
          <div className="icon">
            {link ? (
              <a
                key={`footer_${idx}`}
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                <Icon />
              </a>
            ) : (
              <Icon />
              // <Icon style={{ transform: "scale(1.2)" }} />
            )}
          </div>
        ))}
      </div>

      <div className="firstSection section" id="about">
        <div>
          <img loading="lazy" src={sample} alt="Sample" />
        </div>
        <div className="infoText">
          <h2> What is Cool Cats FC?</h2>
          <p className="description">
            Cool Cats FC (“Football Club”) is a collection of 4,000 NFTs created
            by Cool Cats, Animoca Brands, and OneFootball Labs. The collection
            seeks to gather the Cool Cats community and football fans globally
            to celebrate the world’s largest football event.
          </p>
        </div>
      </div>

      <div className="secondSection section" id="howItWorks">
        <div className="infoText">
          <h2>How does it work?</h2>
          <p className="description">
            Holders of the Cool Cats FC will own a Dynamic NFT representing one
            of the 32 playing countries in the tournament. When teams advance to
            the Round of 16, Quarter-finals, Semi-finals, Final, and ultimate
            Championship, the Cool Cats FC NFTs associated with those teams will
            evolve to mark the team's progression.
          </p>
          <br></br>
          <p>
            When teams advance, holders of those teams' NFTs will also get a
            chance to qualify for a range of rewards including game-worn and
            signed merchandise, once-in-a-lifetime IRL experiences, and digital
            collectibles. In addition, everyone is eligible to participate in
            community-led events in the Cool Cats FC Discord channel to earn
            rewards as well.
          </p>
        </div>
        <div className="grid-container">
          {gridItems.map((item) => (
            <div className="grid-item">
              <img loading="lazy" srcSet={item.img + " 2x"} alt="starter"></img>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="section">
        <HoldersJourney />
      </div>
      <div className="section" id="rewards">
        <Rewards />
      </div>
    </SectionWrapper>
  );
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* width: 1440px; */
  /* height: 1387px; */
  background: #000000;
  color: white;

  padding-left: ${globalPadding.large};
  padding-right: ${globalPadding.large};
  padding-bottom: 96px;

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .grid-item {
    padding: 6px;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 12px;
    img {
      object-fit: cover;
      padding-bottom: 4px;

      @media screen and (max-width: ${deviceWidth.mobile768}) {
        width: 84.99px;
        height: auto;
      }
    }
  }

  & .section {
    margin-top: 80px;
    padding-top: 20px;
    width: 100%;
  }

  @media screen and (max-width: ${deviceWidth.desktopMd}) {
    padding-left: ${globalPadding.medium};
    padding-right: ${globalPadding.medium};
  }

  @media screen and (max-width: ${deviceWidth.desktopXs}) {
    padding-left: ${globalPadding.small};
    padding-right: ${globalPadding.small};
  }

  @media screen and (max-width: ${deviceWidth.tabletXl}) {
    padding-left: ${globalPadding.xsmall};
    padding-right: ${globalPadding.xsmall};
  }

  @media screen and (max-width: ${deviceWidth.tablet}) {
    padding-left: ${globalPadding.mobile};
    padding-right: ${globalPadding.mobile};
  }

  & .description {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  & .firstSection {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    align-items: center;

    img {
      width: 302px;
      height: auto;

      @media screen and (max-width: ${deviceWidth.mobile768}) {
        width: 185px;
        height: auto;
      }
    }

    @media screen and (max-width: ${deviceWidth.tablet}) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;
    }

    & .infoText {
      padding: 0px 0px 0px 55px;
      overflow: auto;

      @media screen and (max-width: ${deviceWidth.tablet}) {
        margin: 0px;
        padding: 16px 55px;
      }

      @media screen and (max-width: ${deviceWidth.mobile768}) {
        max-width: 100%;
        padding: 0px;

        h2 {
          white-space: normal !important;
        }
      }
    }
  }

  & .secondSection {
    display: flex;
    flex-direction: row;

    align-items: center;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 26px;
      text-align: center;
    }

    & .infoText {
      padding: 0px 55px 0px 0px;
      @media screen and (max-width: ${deviceWidth.tablet}) {
        margin: 0px;
        padding: 16px 55px;
      }
    }
  }

  h2 {
    color: white;
    margin-bottom: 32px;

    font-family: "CoolCats Handwriting";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    white-space: nowrap;
  }

  & .icon-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 64px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      flex-direction: column;
    }
  }

  & .icon {
    padding-right: 32px;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      padding-top: 32px;
      padding-right: 0px;
      text-align: center;
    }
  }

  & .header {
    /* font-family: "Fixture Ultra"; */
    font-style: normal;
    font-weight: 600;
    font-size: 128px;
    line-height: 154px;

    background-color: #000000;
  }

  & .subtext {
    /* font-family: "Fixture Ultra Italic"; */
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
  }

  & .infotext {
    /* font-family: "Fixture Ultra"; */
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 10px;
    margin-top: 22px;

    background-color: #000000;
    display: inline-block;

    p {
      margin: 0px;
      padding: 0px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;
