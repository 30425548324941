import { EnumCrowdSalePhase } from "./chain";
import MerkelProofP1 from "../config/proofs/phase1.json";

import MerkelProofP2 from "../config/proofs/phase2.json";
import MerkelProofP3 from "../config/proofs/phase3.json";

const phase1StartTime =
    Number(process.env.REACT_APP_SALE_PHASE1_START_TIME) || 1668722400;
const phase2StartTime =
    Number(process.env.REACT_APP_SALE_PHASE2_START_TIME) || 1668765600;
const phase3StartTime =
    Number(process.env.REACT_APP_SALE_PHASE3_START_TIME) || 1668808800;
const phase3EndTime =
    Number(process.env.REACT_APP_SALE_PHASE3_END_TIME) || 1668852000;
const phase4StartTime =
    Number(process.env.REACT_APP_SALE_PHASE4_START_TIME) || 1668870000;
const phase4EndTime =
    Number(process.env.REACT_APP_SALE_PHASE4_END_TIME) || 1668913200;

const CROWDSALE_PHASE_DETAILS = {
    [EnumCrowdSalePhase.SALE_PHASE_1]: {
        proof: MerkelProofP1,
        startingTime: phase1StartTime,
        endingTime: phase2StartTime,
    },
    [EnumCrowdSalePhase.SALE_PHASE_2]: {
        proof: MerkelProofP2,
        startingTime: phase2StartTime,
        endingTime: phase3StartTime,
    },
    [EnumCrowdSalePhase.SALE_PHASE_3]: {
        proof: MerkelProofP3,
        startingTime: phase3StartTime,
        endingTime: phase3EndTime,
    },
    [EnumCrowdSalePhase.SALE_PHASE_4]: {
        proof: [],
        startingTime: phase4StartTime,
        endingTime: phase4EndTime,
    },
};

export const getCrowdSaleDetails = (phase: number | null = null) => {
    const _phase = phase !== null ? phase : getPresalePhase();
    // const _phase = 1;
    return {
        ...CROWDSALE_PHASE_DETAILS[_phase],
        phase: _phase,
    };
};

export const getPresalePhase = (): number => {
    const currentTime = Math.floor(Date.now() / 1000);

    const timings = [
        phase1StartTime,
        phase2StartTime,
        phase3StartTime,
        phase3EndTime,
        phase4StartTime,
        phase4EndTime,
    ];

    const indexToIns = timings
        .concat(currentTime)
        .sort((a, b) => a - b)
        .indexOf(currentTime);

    console.log(timings.concat(currentTime).sort((a, b) => a - b));
    console.log({ indexToIns, currentTime });
    return indexToIns;
    // }
};
