/* eslint-disable no-throw-literal */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ContractType, getContractAddress } from "../config/chain";
import { ContractService } from "./contract";

export class InventoryContractService extends ContractService {
  public getTotalSupply = async () => {
    const totalSupply = await this.readContract.methods.totalSupply().call();
    return totalSupply;
  };
}

/* Creating a new instance of the InventoryContractService class. */
export const generateInventoryContract = () => {
  const crowdSaleContractConfig = getContractAddress(ContractType.INVENTORY);
  return new InventoryContractService(
    crowdSaleContractConfig.address,
    crowdSaleContractConfig.abi as any
  );
};
