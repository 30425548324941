import PublicSaleContract from "../contracts/CCOFPublicsale.json";
import InventoryContract from "../contracts/CCOFInventory.json";
import AllowedSaleContract from "../contracts/CCOFAllowedSale.json";

export interface IChainConfigs {
  [key: string]: IBlockchainInfo;
}

export enum ContractType {
  INVENTORY = "inventory",
  SALE_PHASE_1 = "crowdSalePhase1",
  SALE_PHASE_2 = "crowdSalePhase2",
  SALE_PHASE_3 = "crowdSalePhase3",
  SALE_PHASE_4 = "crowdSalePhase4",
}

export const EnumCrowdSalePhase = {
  NOT_STARTED: 0,
  SALE_PHASE_1: 1,
  SALE_PHASE_2: 2,
  SALE_PHASE_3: 3,
  PRESALE_OVER: 4,
  SALE_PHASE_4: 5,
  SALE_OVER: 6,
};

export const isMintPhase = (phase: number) => {
  if (
    phase === EnumCrowdSalePhase.SALE_PHASE_1 ||
    phase === EnumCrowdSalePhase.SALE_PHASE_2 ||
    phase === EnumCrowdSalePhase.SALE_PHASE_3 ||
    phase === EnumCrowdSalePhase.SALE_PHASE_4
  )
    return true;
  return false;
};

export const CrowdSaleNames = [
  "COOL CATS AL",
  "COOL CATS AL",
  "PARTNER PROJECTS AL",
  "COOL PETS AL",
  "PUBLIC MINT",
  "PUBLIC MINT",
  "CLOSED",
];

export const MintTexts = [
  "MINTING SOON",
  "MINT NOW LIVE!",
  "MINT NOW LIVE!",
  "MINT NOW LIVE!",
  `AL MINT IS CLOSED
  PUBLIC MINT STARTS SOON!
  `,
  "MINT NOW LIVE!",
  "MINT IS OVER!",
];

export interface IBlockchainInfo {
  name: string;
  symbol: string;
  shortName: string;
  icon?: { black: string; white: string; color: string };
  logoUrl?: string;
  explorerUrl?: string;
  rpcUrls: string[];
  chainId: string;
}

export const chainConfigs: IChainConfigs = {
  mainnet: {
    name: "Ethereum Mainnet",
    rpcUrls: ["https://mainnet.infura.io/v3/0aab62a52a9a4340b584578c39a5a4a3"],
    chainId: "0x1",
    symbol: "ETH",
    shortName: "Ethereum",
    explorerUrl: "https://etherscan.io/",
  },
  testnet: {
    name: "Mumbai Test Network",
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    chainId: "0x13881",
    symbol: "MATIC",
    shortName: "MUMBAI",
    explorerUrl: "https://polygonscan.com/",
  },

  devnet: {
    name: "Goerli Test Network",
    rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    chainId: "0x5",
    symbol: "gETH",
    shortName: "Goerli",
    explorerUrl: "https://goerli.etherscan.io/",
  },
};

export const getChainInfo = () => {
  return chainConfigs[process.env.REACT_APP_CHAIN || "mainnet"];
};

export const contractConfigs = {
  crowdSalePhase1: {
    address:
      process.env.REACT_APP_PRESALE_PHASE1_CONTRACT_ADDRESS ||
      "0x1ac03908775a3Ac523594D8c5A49cb0E429A7756",
    abi: AllowedSaleContract.abi,
  },
  crowdSalePhase2: {
    address:
      process.env.REACT_APP_PRESALE_PHASE2_CONTRACT_ADDRESS ||
      "0x2c28aeace592d6d5A79b7AAdB625b68a1f9ABd40",
    abi: AllowedSaleContract.abi,
  },
  crowdSalePhase3: {
    address:
      process.env.REACT_APP_PRESALE_PHASE3_CONTRACT_ADDRESS ||
      "0x2754CBc35DD9A75fc3B62ECDc9f43BF075841773",
    abi: AllowedSaleContract.abi,
  },
  crowdSalePhase4: {
    address:
      process.env.REACT_APP_PUBLICSALE_CONTRACT_ADDRESS ||
      "0x35520fe9b8371A14e5ebE01e62Def110b9D85b67",
    abi: PublicSaleContract.abi,
  },
  inventory: {
    address:
      process.env.REACT_APP_INVENTORY_CONTRACT_ADDRESS ||
      "0xad27c6Ef1C2E37A78514a9e54f5C35a8BF51601D",
    abi: InventoryContract.abi,
  },
};

export const getContractAddress = (type: ContractType) => {
  return contractConfigs[type];
};
