import { Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { deviceWidth, THEME } from "../../../config";
import { LitePaperLink } from "../../../constants";

interface IChallengeQuestion {
  handler: (arg0: boolean, answer: string) => void;
  refresh?: number;
}

const ChallengeQuestion: React.FC<IChallengeQuestion> = ({
  handler,
  refresh,
}) => {
  const [answer, setAnswer] = useState("");
  const [words, setWords] = useState(0);

  const [litepaperChecked, setLitepaperChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    if (
      answer.length >= 20 &&
      answer.length <= 150 &&
      litepaperChecked &&
      termsChecked
    ) {
      handler(true, answer);
    } else {
      handler(false, answer);
    }
  }, [answer, words, litepaperChecked, termsChecked, handler]);

  useEffect(() => {
    setAnswer("");
    setWords(0);
    setLitepaperChecked(false);
    setTermsChecked(false);
  }, [refresh]);

  return (
    <Wrapper>
      <div className="container">
        <div className="terms">
          <p>
            I HAVE READ AND UNDERSTAND{" "}
            <a href={LitePaperLink} rel="noopener noreferrer" target="_blank">
              THE LITEPAPER
            </a>
          </p>
          <div>
            <Checkbox
              checked={litepaperChecked}
              onChange={() => setLitepaperChecked((prev) => !prev)}
            ></Checkbox>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="terms">
          <p>
            I HAVE READ AND AGREE TO ALL{" "}
            <a href={"/terms"} rel="noopener noreferrer" target="_blank">
              TERMS AND CONDITIONS
            </a>
          </p>
          <div>
            <Checkbox
              checked={termsChecked}
              onChange={() => setTermsChecked((prev) => !prev)}
            ></Checkbox>
          </div>
        </div>
      </div>

      {litepaperChecked && termsChecked && (
        <div className="container">
          <p className="title">CHALLENGE QUESTION:</p>
          <br></br>
          <p className="question">
            Who is your favorite footballer (soccer) and why??
          </p>
          <p className="condition">
            (No more than 50 words, at least 20 characters)
          </p>
          <br></br>
          <TextArea
            rows={4}
            value={answer}
            onChange={(e) => {
              setAnswer(e.target.value);
              setWords(e.target.value.split(" ").length);
            }}
          />
          {/* <p className="count">{words}/50 words</p> */}
          <p className="warning">
            FAILURE TO PROVIDE FULL AND ACCURATE ANSWERS TO THE CHALLENGE
            QUESTION WILL RESULT IN INELIGIBILITY FOR REWARDS.
          </p>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 22px;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${THEME.colors.brand};
    border-color: ${THEME.colors.brand};

    &:hover:before {
      background-color: ${THEME.colors.brand};
    }
  }

  @media screen and (max-width: ${deviceWidth.mobile768}) {
    padding: 0px 30px;
  }

  & .container {
    background-color: transparent;
    border: 1px solid #000000;
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 14px 22px 14px 20px;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    min-width: 512px;

    @media screen and (max-width: ${deviceWidth.mobile768}) {
      padding: 12px;

      min-width: 0px;
    }

    & .title {
      font-weight: bold;
    }

    & .question {
      font-weight: bold;
    }

    & .condition {
      font-style: italic;
    }

    & .warning {
      font-style: italic;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      padding: 20px;
      text-align: center;

      @media screen and (max-width: ${deviceWidth.mobile768}) {
        padding: 4px;
      }
    }

    & .count {
      align-self: flex-end;
    }

    & .terms {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;

      p {
        padding-right: 22px;
      }

      a {
        color: #0032e3;
        text-decoration: underline;
      }

      .ant-checkbox-inner,
      .ant-checkbox-input {
        transform: scale(1.5);
      }
    }
  }
`;

export default ChallengeQuestion;
