import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Card, Divider, Spin } from "antd";
import { deviceWidth, globalPadding, THEME } from "../../../config";
import { generateCrowdsaleContract } from "../../../services/crowdSaleContract";

import MintNotStarted from "./MintNotStarted";
import MintClosed from "./MintClosed";
import MintSoldOut from "./MintSoldOut";
import MintPaused from "./MintPaused";
import MintLive from "./MintLive";
import { getCrowdSaleDetails } from "../../../config/sale";
import {
    CrowdSaleNames,
    EnumCrowdSalePhase,
    isMintPhase,
    MintTexts,
} from "../../../config/chain";
import MintPresaleOver from "./MintPresaleOver";
import mintCardImg from "../../../assets/CardPackMint.png";
import HomePageTopSection from "../HomePageTopSection";
import InfoTable from "./InfoTable";
import { formatWei } from "../../../helpers/formatters";
import { generateInventoryContract } from "../../../services/inventoryContract";

export default function MintPage() {
    const [crowdSaleDetails, setCrowdSaleDetails] = useState(
        getCrowdSaleDetails()
    );

    const [fetchMintInfoLoading, setFetchMintInfoLoading] = useState(false);
    const [mintPrice, setMintPrice] = React.useState(0);
    const [totalCap, setTotalCap] = React.useState(0);
    const [totalMinted, setTotalMinted] = React.useState(0);
    const [isMintPaused, setIsMintPaused] = React.useState(0);
    const [totalSupply, setTotalSupply] = React.useState(0);

    const isSaleStarted = useMemo(() => {
        return crowdSaleDetails.phase !== EnumCrowdSalePhase.NOT_STARTED;
    }, [crowdSaleDetails.phase]);

    const isPresaleOver = useMemo(() => {
        return crowdSaleDetails.phase === EnumCrowdSalePhase.PRESALE_OVER;
    }, [crowdSaleDetails.phase]);

    const isSaleClosed = useMemo(() => {
        return crowdSaleDetails.phase === EnumCrowdSalePhase.SALE_OVER;
    }, [crowdSaleDetails.phase]);

    const isSoldOut = useMemo(() => {
        return totalSupply === 4000;
        // return totalMinted && totalCap && totalMinted === totalCap;
    }, [totalSupply]);

    const crowdSaleContract = React.useMemo(() => {
        return generateCrowdsaleContract();
    }, []);

    const inventoryContract = React.useMemo(() => {
        return generateInventoryContract();
    }, []);

    const fetchTotalSupply = React.useCallback(async () => {
        try {
            const totalSupply = await inventoryContract.getTotalSupply();

            setTotalSupply(totalSupply);
        } catch (error) {
            console.log("Error");
        }
    }, [inventoryContract]);

    const fetchCrowdSaleStatus = React.useCallback(async () => {
        try {
            const tokenSold = await crowdSaleContract.getTokenSold();
            const tokenCap = await crowdSaleContract.getTokenCap();

            setTotalCap(tokenCap);
            setTotalMinted(tokenSold);
        } catch (error) {
            console.log("Error");
        }
    }, [crowdSaleContract]);

    const fetchMintInfo = React.useCallback(async () => {
        setFetchMintInfoLoading(true);
        try {
            const price = await crowdSaleContract.getTokenPrice();

            const isPaused = await crowdSaleContract.getPauseStatus();
            setIsMintPaused(isPaused);

            setMintPrice(price);
        } catch (error) {
            console.log("Error while fetching Price");
        } finally {
            setFetchMintInfoLoading(false);
        }
    }, [crowdSaleContract, crowdSaleDetails]);

    React.useEffect(() => {
        fetchMintInfo();
        fetchCrowdSaleStatus();
        fetchTotalSupply();
    }, [
        fetchMintInfo,
        fetchCrowdSaleStatus,
        fetchTotalSupply,
        crowdSaleDetails,
    ]);

    const updateValues = async () => {
        console.log("Updating values");

        setTimeout(() => {
            setCrowdSaleDetails(getCrowdSaleDetails());
        }, 1000);
    };

    useEffect(() => {
        console.log("Updated crowdsale details", { crowdSaleDetails });
    }, [crowdSaleDetails]);

    const getCountdownTime = (phase: number) => {
        if (phase === 0 || phase === 1 || phase === 2 || phase === 4)
            return getCrowdSaleDetails(phase + 1).startingTime;
        else if (phase === 3 || phase === 5)
            return getCrowdSaleDetails(phase).endingTime;
        else return "-";
    };

    const getTimeTitle = (phase: number) => {
        if (
            phase === EnumCrowdSalePhase.NOT_STARTED ||
            phase === EnumCrowdSalePhase.PRESALE_OVER ||
            isSoldOut
        ) {
            return "TIME UNTIL START";
        } else {
            return "TIME REMAINING";
        }
    };

    const adjustIfSoldOut = (value: number, adjust: Boolean) => {
        if (adjust) return value + 1;
        else return value;
    };

    const getLastSalePhase = (phase: number) => {
        if (phase >= EnumCrowdSalePhase.SALE_PHASE_4)
            return EnumCrowdSalePhase.SALE_PHASE_4;
        if (phase >= EnumCrowdSalePhase.SALE_PHASE_3)
            return EnumCrowdSalePhase.SALE_PHASE_3;
        if (phase >= EnumCrowdSalePhase.SALE_PHASE_2)
            return EnumCrowdSalePhase.SALE_PHASE_2;
        else return EnumCrowdSalePhase.SALE_PHASE_1;
    };

    const getTotalCap = (phase: number) => {
        if (phase === 1) return 1513;
        if (phase === 2) return 1800;
        if (phase === 3) return 2313;
        if (phase > 3) return 4000;
    };

    const mintInfo = useMemo(
        () => [
            // Adjust the mint window value to next one if sold out and not in last 2 phases
            {
                title: "MINT WINDOW",
                value: CrowdSaleNames[
                    adjustIfSoldOut(
                        crowdSaleDetails.phase,
                        Boolean(isSoldOut) &&
                        crowdSaleDetails.phase !==
                        EnumCrowdSalePhase.SALE_PHASE_4 &&
                        crowdSaleDetails.phase !==
                        EnumCrowdSalePhase.SALE_OVER
                    )
                ],
            },
            {
                title: getTimeTitle(crowdSaleDetails.phase),

                // Adjust value only if phase 3 is sold out (since we need phase 4's time) and not in last 2 phases
                value:
                    isSoldOut &&
                        crowdSaleDetails.phase === EnumCrowdSalePhase.SALE_PHASE_4
                        ? "-"
                        : getCountdownTime(
                            adjustIfSoldOut(
                                crowdSaleDetails.phase,
                                Boolean(isSoldOut) &&
                                crowdSaleDetails.phase ===
                                EnumCrowdSalePhase.SALE_PHASE_3 &&
                                crowdSaleDetails.phase !==
                                EnumCrowdSalePhase.SALE_PHASE_4 &&
                                crowdSaleDetails.phase !==
                                EnumCrowdSalePhase.SALE_OVER
                            )
                        ),
            },
            {
                title: "# MINTED/WINDOW MAX",
                value: `${totalSupply}/${getTotalCap(crowdSaleDetails.phase)}`,
            },
            {
                title: "MINT PRICE",
                value:
                    crowdSaleDetails.phase !== EnumCrowdSalePhase.SALE_OVER
                        ? "0.1 ETH"
                        : "-"

            },
        ],
        [crowdSaleDetails, totalSupply, isSoldOut, mintPrice]
    );

    return (
        <>
            <Wrapper>
                <HomePageTopSection
                    sideImageSrc={mintCardImg}
                    subText={

                        fetchMintInfoLoading ? " " :
                            isMintPaused
                                ? "MINT IS PAUSED":
                                isSoldOut
                                        ? `MINT SOLD OUT!!`
                                : crowdSaleDetails.phase ===
                                    EnumCrowdSalePhase.SALE_OVER
                                    ? MintTexts[crowdSaleDetails.phase]
                                   
                                        : MintTexts[crowdSaleDetails.phase]
                    }
                    mintPage={true}
                />


                {fetchMintInfoLoading ? (
                    <Spin size="large" />) : <>
                    <InfoTable
                        infoList={mintInfo}
                        handleCountdownComplete={() =>
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000)
                        }
                    />
                    <div className="membership-info top-wra">
                        <div className="membership-info-title content">
                            {/* <h1 className="title">Mint NFT {crowdSaleDetails.phase}</h1> */}
                        </div>

                        {isMintPhase(crowdSaleDetails.phase) ? (
                            fetchMintInfoLoading ? (
                                <Spin size="large" />
                            ) : isSoldOut ? (
                                // <MintSoldOut />
                                <></>
                            ) : !isSaleStarted ? (
                                // <MintNotStarted
                                //   mintOpeningTime={
                                //     getCrowdSaleDetails(crowdSaleDetails.phase + 1).startingTime
                                //   }
                                //   handleOnCountdownEnd={() => console.log("True")}
                                // />
                                <></>
                            ) : isSaleClosed ? (
                                // <MintNotStarted
                                //   mintOpeningTime={
                                //     getCrowdSaleDetails(crowdSaleDetails.phase + 1).startingTime
                                //   }
                                //   handleOnCountdownEnd={() => console.log("True")}
                                // />

                                // <MintPresaleOver
                                //   mintOpeningTime={
                                //     getCrowdSaleDetails(crowdSaleDetails.phase + 1).startingTime
                                //   }
                                //   handleOnCountdownEnd={() => console.log("True")}
                                // />

                                // <MintLive
                                //   refetchOnSuccess={fetchCrowdSaleStatus}
                                //   totalCap={totalCap}
                                //   totalMinted={totalMinted}
                                //   mintPrice={mintPrice}
                                // />
                                // <MintClosed></MintClosed>
                                <></>
                            ) : isMintPaused ? (
                                <></>
                            ) : isPresaleOver ? (
                                // <MintPresaleOver
                                //   mintOpeningTime={
                                //     getCrowdSaleDetails(crowdSaleDetails.phase + 1).startingTime
                                //   }
                                //   handleOnCountdownEnd={() => console.log("True")}
                                // />
                                <></>
                            ) : (
                                <MintLive
                                    refetchOnSuccess={() => {
                                        fetchCrowdSaleStatus();
                                        fetchTotalSupply();
                                    }}
                                    totalCap={totalCap}
                                    totalMinted={totalMinted}
                                    mintPrice={mintPrice}
                                    phase={crowdSaleDetails.phase}
                                />
                            )
                        ) : (
                            <></>
                        )}
                    </div>

                </>}


            </Wrapper>

        </>
    );
}

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f0f0e5;

    /* margin: 226px auto 180px auto; */
    gap: 0px;
    /* max-width: 1505px; */

    & .membership-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        gap: 44px;

        @media screen and (max-width: ${deviceWidth.bp1728}) {
            gap: 44px;
        }

        & .membership-info-title {
            & > h1 {
                ${THEME.typography.heading10}

                @media screen and (max-width: ${deviceWidth.bp1536}) {
                    ${THEME.typography.heading9}
                }

                @media screen and (max-width: ${deviceWidth.bp768}) {
                    ${THEME.typography.heading7}
                }
            }
        }

        // & > p {
        //     margin-top: 48px;
        // }
    }

    & .minting-container {
        display: flex;
        flex-direction: column;
        gap: 22px;
        align-items: center;
        max-width: 512px;

        & .card-container {
            background-color: transparent;
            font-family: "Poppins";
        }

        @media (max-width: ${deviceWidth.tablet}) {
            gap: 22px;
            flex-direction: column;
        }
    }
`;
export const MemberCard = styled(Card)`
    padding: 32px;
    height: fit-content;
    max-width: 540px;
    background: #ffffff;
    box-shadow: 0px 16px 64px rgba(30, 44, 106, 0.12);
    border-radius: 16px;
    display: flex;
    border: 0;
    text-align: left;
    & .ant-card-body {
        padding: 0;
    }

    & .card-header {
        ${THEME.typography.heading5}
        color: ${THEME.colors.contentSecondary};
        margin-bottom: 8px;
    }

    & .card-body {
        ${THEME.typography.body2}
        color: ${THEME.colors.contentMuted}
    }

    .membership {
        padding: 32px 0;
    }

    & .membership-option {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        margin-top: 16px;
        place-items: center;
        border: 1px solid ${THEME.colors.borderSecondary};
        border-radius: 100px;
        justify-content: space-between;
        align-items: center;
        height: 59px;
        padding: 0 16px;
    }

    & .membership-option:first-child {
        margin-top: 0;
    }

    & .membership-option.checked {
        background: ${THEME.colors.secondary};
        border-color: ${THEME.colors.secondary};
    }

    & .membership-option.checked > .membership-item {
        color: ${THEME.colors.contentPrimary};
    }

    & .membership-option.checked > .membership-item > input {
        background: ${THEME.colors.surfaceSubtle};
    }

    & .membership-item {
        display: flex;
        flex-direction: row ${THEME.typography.strong4};
        font-size: 18px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        font-weight: 600;
        color: ${THEME.colors.contentSecondary};
    }

    & .membership-mint {
        ${THEME.typography.label2}
        font-size: 14px;
        font-weight: 600;
        color: ${THEME.colors.contentMuted};
        @media screen and (max-width: ${deviceWidth.bp440}) {
            display: none;
        }
    }

    & .mint-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 32px;
    }
    & .mint-btn {
        display: flex;
        cursor: pointer;
        height: 44px;
        width: 44px;
        border-radius: 100px;
        background: #d8deff;
        color: ${THEME.colors.contentPrimary};
        align-items: center;
        text-align: center;
        justify-content: center;
        user-select: none;
        font-weight: 600;
    }
    & .mint-btn.disabled {
        background: #f1f3f6;
        color: ${THEME.colors.contentMuted};
    }
    .danger {
        color: red;
        text-align: center;
    }
    .disabled {
        font-style: bold;
        background: #f1f3f6;
        color: ${THEME.colors.contentMuted};
        &:hover {
            background: #f1f3f6;
            color: ${THEME.colors.contentMuted};
        }
    }

    & .mint-input {
        ${THEME.typography.label6}
    }
    & .mint-info {
        ${THEME.typography.body2}
        color: ${THEME.colors.contentMuted};
        place-items: center;
        text-align: center;
        margin-top: 10px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;
