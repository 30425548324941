import React from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import { deviceWidth, globalPadding, THEME } from "../../../config/theme";

interface IInfoTable {
  infoList: { title: string; value: string | number }[];
  handleCountdownComplete: () => void;
}

const InfoTable: React.FC<IInfoTable> = ({
  infoList,
  handleCountdownComplete,
}) => {
  return (
    <Wrapper>
      <div className="info-table">
        {infoList.map((info, i) =>
          i === 1 && typeof info.value === "number" ? (
            <React.Fragment key={info.title}>
              <div className="container">
                <p className="title">{info.title}</p>
                <p className="value">
                  <Countdown
                    date={(info.value as number) * 1000}
                    onComplete={handleCountdownComplete}
                  ></Countdown>
                </p>
              </div>
              {i !== infoList.length - 1 && (
                <div className="headerDivider"></div>
              )}
              {i !== infoList.length - 1 && (
                <hr className="headerDividerMobile"></hr>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment key={info.title}>
              <div className="container">
                <p className="title">{info.title}</p>
                <p className="value">{info.value}</p>
              </div>
              {i !== infoList.length - 1 && (
                <div className="headerDivider"></div>
              )}
              {i !== infoList.length - 1 && (
                <hr className="headerDividerMobile"></hr>
              )}
            </React.Fragment>
          )
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;

  & .info-table {
    display: flex;
    flex-direction: row;
    background: black;
    color: ${THEME.colors.brand};
    border: 1px solid #000000;
    justify-content: center;
    margin-left: ${globalPadding.large};
    margin-right: ${globalPadding.large};

    @media screen and (max-width: ${deviceWidth.desktopMd}) {
      margin-left: ${globalPadding.medium};
      margin-right: ${globalPadding.medium};
    }

    @media screen and (max-width: ${deviceWidth.desktopXs}) {
      margin-left: ${globalPadding.small};
      margin-right: ${globalPadding.small};
    }

    @media screen and (max-width: ${deviceWidth.tabletXl}) {
      margin-left: ${globalPadding.xsmall};
      margin-right: ${globalPadding.xsmall};
    }

    @media screen and (max-width: ${deviceWidth.tablet}) {
      margin-left: ${globalPadding.mobile};
      margin-right: ${globalPadding.mobile};
    }

    @media screen and (max-width: ${deviceWidth.mobile768}) {
      flex-direction: column;
    }

    .headerDivider {
      border-left: 1px solid grey;

      height: 80px;
      right: 0px;
      top: 20px;
      position: relative;

      @media screen and (max-width: ${deviceWidth.mobile768}) {
        display: none;
      }
    }

    .headerDividerMobile {
      display: none;
      color: grey;
      @media screen and (max-width: ${deviceWidth.mobile768}) {
        display: block;
        width: 75%;
      }
    }

    justify-content: space-evenly;

    & .container {
      display: flex;
      flex-direction: column;
      padding: 24px 0px;

      @media screen and (max-width: ${deviceWidth.mobile768}) {
        padding: 12px;
      }
    }

    & .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      white-space: nowrap;
      @media screen and (max-width: ${deviceWidth.mobile768}) {
        white-space: normal;
      }
    }

    & .value {
      font-family: "Fixture";
      font-style: italic;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-align: center;

      white-space: nowrap;
      @media screen and (max-width: ${deviceWidth.mobile768}) {
        white-space: normal;
      }
    }
  }
`;

export default InfoTable;
