import React from "react";
import styled from "styled-components";

export default function NotFoundPage() {
  return (
    <SectionWrapper>
      <p>PAGE NOT FOUND</p>
    </SectionWrapper>
  );
}
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  height: 800px;

  p {
    font-size: 64px;
  }
`;
