import { Web3ReactProvider } from "@web3-react/core";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { MetaMaskProvider } from "../hoc/Metamask";

import Web3 from "web3";
import HomePage from "../pages/HomePage";
import MintPage from "../components/organisms/Mint";
import { LayoutProvider } from "../hoc/LayoutProvider";
import NotFoundPage from "../pages/NotFoundPage";
import ScrollToTop from "../hoc/ScrollToTop";
import TermsPage from "../pages/TermsAndConditions";

function getLibrary(provider: any, connector: any) {
  return new Web3(provider);
}

const Routing = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetaMaskProvider>
        <BrowserRouter>
          <ScrollToTop />
          <LayoutProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/ccfc-mint" element={<MintPage />} />
              <Route path="/mint" element={ <Navigate to="/ccfc-mint" />} />
              <Route path="/terms" element={<TermsPage />} />
            </Routes>
          </LayoutProvider>
        </BrowserRouter>
      </MetaMaskProvider>
    </Web3ReactProvider>
  );
};

export default Routing;
