import { GlobalStyles } from "./styles/global-styles";
import Routing from "./routes";
import "antd/dist/antd.min.css";
import "./App.css";

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
  return (
    <>
      <GlobalStyles />
      <Routing />
    </>
  );
}

export default App;
