import { collection, addDoc, Timestamp } from "firebase/firestore";
import { firebaseDB, firebaseDBName } from "../config/firebase";

export interface IUserAnswers {
  walletAddress: string;
  answer: string;
}

const userAnswersRef = collection(firebaseDB, firebaseDBName);

export const addUserAnswer = async (data: IUserAnswers) => {
  try {
    const docRef = await addDoc(userAnswersRef, {
      walletAddress: data.walletAddress,
      answer: data.answer,
      createdAt: Timestamp.now(),
    });
    return docRef;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
