import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  collectLink,
  generalUseLink,
  holdemLink,
  LitePaperLink,
  personalUseLink,
  pickLink,
} from "../../constants";
import crest from "../../assets/coolcatsCrest.png";
import { deviceWidth, globalPadding } from "../../config";
import Button from "../../components/atoms/Button";

export default function TermsPage() {
  return (
    <SectionWrapper>
      <Link to={"/"}>
        <img src={crest} alt="Crest" className="crest" />
      </Link>
      <h2>TERMS AND CONDITIONS</h2>
      <a href={personalUseLink} rel="noopener noreferrer" target="_blank">
        Personal Use NFT License
      </a>
      <a href={generalUseLink} rel="noopener noreferrer" target="_blank">
        General Rules
      </a>
      <a href={holdemLink} rel="noopener noreferrer" target="_blank">
        Hold’em Game
      </a>
      <a href={pickLink} rel="noopener noreferrer" target="_blank">
        Pick’em Game Table
      </a>
      <a href={collectLink} rel="noopener noreferrer" target="_blank">
        Collect’em Game Table
      </a>

      <Button
        btntext={"CLOSE"}
        className="btn-style btn-style-success"
        btntype="neutral"
        btnSize={"large"}
        onClick={() => {
          window.open("about:blank", "_self");
          window.close();
        }}
        notRounded
      />
    </SectionWrapper>
  );
}
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  min-height: 800px;
  background-color: #f0f0e5;

  padding-top: 48px;
  padding-left: ${globalPadding.large};
  padding-right: ${globalPadding.large};

  @media screen and (max-width: ${deviceWidth.desktopMd}) {
    padding-left: ${globalPadding.medium};
    padding-right: ${globalPadding.medium};
  }

  @media screen and (max-width: ${deviceWidth.desktopXs}) {
    padding-left: ${globalPadding.small};
    padding-right: ${globalPadding.small};
  }

  @media screen and (max-width: ${deviceWidth.tabletXl}) {
    padding-left: ${globalPadding.xsmall};
    padding-right: ${globalPadding.xsmall};
    text-align: center;
    /* align-items: center; */
  }

  @media screen and (max-width: ${deviceWidth.tablet}) {
    padding-left: ${globalPadding.mobile};
    padding-right: ${globalPadding.mobile};
    text-align: center;
    align-items: center;
  }
  padding-bottom: 24px;
  gap: 28px;

  & .crest {
    @media screen and (max-width: ${deviceWidth.mobile768}) {
      width: 72px;
      height: auto;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
  }

  a {
    font-size: 22px;
    font-size: 30px;
    line-height: 45px;
    text-decoration-line: underline;

    color: #0032e3;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.5);
  }
`;
