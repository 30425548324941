import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    "AIzaSyDjmBrbG3XEqOx8e7sract1YII5c_tL0As",
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    "coolcatsnft-onefootball.firebaseapp.com",
  projectId:
    process.env.REACT_APP_FIREBASE_PROJECT_ID || "coolcatsnft-onefootball",
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
    "coolcatsnft-onefootball.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID || "1096216661544",
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ||
    "1:1096216661544:web:4dc4e8b2a34ca3bd532f1d",
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// console.log({ firebaseConfig })

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDB = getFirestore(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);

export const firebaseDBName = "user_answers";
