import { ReactComponent as TwitterIcon } from "../assets/icons/twitter.svg";
import { ReactComponent as DiscordIcon } from "../assets/icons/discord.svg";
import { ReactComponent as AnimocaIcon } from "../assets/footer/animoca.svg";
import { ReactComponent as OneFootballIcon } from "../assets/footer/onefootball.svg";
import { ReactComponent as CoolCatsIcon } from "../assets/footer/coolcats.svg";
import { ReactComponent as OpenSeaIcon } from "../assets/OpenSea.svg";

import { ReactComponent as AnimocaIconLight } from "../assets/footer/animoca-light.svg";
import { ReactComponent as OneFootballIconLight } from "../assets/footer/onefootball-light.svg";
import { ReactComponent as CoolCatsIconLight } from "../assets/footer/coolcats-light.svg";

export const socialLinks = {
  twitter: "https://twitter.com/CoolCatsFC",
  discord: "https://discord.gg/coolcatsnft",
  opensea: "https://opensea.io/collection/cool-cats-fc",
};

export const footerLinks = {
  coolcats: "https://www.coolcatsnft.com/about",
  onefootball: "https://aera.onefootball.com/",
  animoca: "https://www.animocabrands.com/",
};

export const LitePaperLink =
  "https://content.coolcatsnft.com/ccfc-litepaper.pdf";

export const personalUseLink =
  "https://content.coolcatsnft.com/ccfc-personal-use-nft-license.pdf";
export const generalUseLink =
  " https://content.coolcatsnft.com/ccfc-general-use.pdf";
export const holdemLink =
  "https://content.coolcatsnft.com/ccfc-holdem-game.pdf";
export const pickLink = "https://content.coolcatsnft.com/ccfc-pickem-game.pdf";
export const collectLink =
  "https://content.coolcatsnft.com/ccfc-collectem-game.pdf";

export const socialContentData = [
  {
    title: "Opensea",
    Icon: OpenSeaIcon,
    link: socialLinks.opensea,
  },
  {
    title: "Twitter",
    Icon: TwitterIcon,
    link: socialLinks.twitter,
  },
  {
    title: "Discord",
    Icon: DiscordIcon,
    link: socialLinks.discord,
  },
];

export const footerIcons = [
  {
    Icon: CoolCatsIcon,
    link: footerLinks.coolcats,
  },
  {
    Icon: OneFootballIcon,
    link: footerLinks.onefootball,
  },
  {
    Icon: AnimocaIcon,
    link: footerLinks.animoca,
  },
];

export const infoIcons = [
  {
    Icon: CoolCatsIconLight,
    link: footerLinks.coolcats,
  },
  {
    Icon: OneFootballIconLight,
    link: footerLinks.onefootball,
  },
  {
    Icon: AnimocaIconLight,
    link: footerLinks.animoca,
  },
];
