import React from "react";
import { Button as ButtonComponent, ButtonProps, Spin } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { THEME } from "../../../config/theme";

interface IProps extends ButtonProps {
  btntext: string;
  btnIcon?: any;
  isdisabled?: boolean;
  className?: string;
  to?: string;
  isExternalLink?: boolean;
  notRounded?: boolean;
  btntype?: "primary" | "secondary" | "neutral";
  btnSize?: "large" | "medium" | "small" | "very-small";
}

export const Button: React.FC<IProps> = (props: IProps) => {
  const {
    btntext,
    btnIcon,
    isdisabled,
    to,
    isExternalLink,
    className,
    notRounded,
  } = props;
  const ButtonContent = () => {
    return (
      <ButtonWrapper {...props} className={className} disabled={isdisabled}>
        {btnIcon}
        <span>{btntext}</span>
      </ButtonWrapper>
    );
  };

  if (isExternalLink) {
    return (
      <a target="_blank" href={to || "#"} rel="noreferrer">
        <ButtonContent />
      </a>
    );
  }

  if (!!to) {
    return (
      <Link to={to || "#"}>
        <ButtonContent />
      </Link>
    );
  }
  return <ButtonContent />;
};

const ButtonWrapper = styled(ButtonComponent)<IProps>`
  cursor: pointer;
  /* border-radius: 100px; */
  color: ${({ btntype }: IProps) =>
    btntype !== "primary"
      ? btntype === "secondary"
        ? THEME.colors.primary
        : THEME.colors.brand
      : THEME.colors.primary};
  background: ${({ btntype }: IProps) =>
    btntype === "neutral"
      ? THEME.colors.inverse
      : btntype === "secondary"
      ? "#F0F0E5"
      : THEME.colors.brand};

  border-color: black;
  padding: ${({ btnSize }: IProps) =>
    btnSize === "very-small"
      ? "6px 24px"
      : btnSize === "medium"
      ? "12px 24px"
      : btnSize === "small"
      ? "8px 20px"
      : "16px 32px"};
  border-radius: ${({ notRounded }: IProps) => (notRounded ? "0px" : "105px")};
  font-family: "Poppins";
  font-weight: 600;
  font-size: ${({ btnSize }: IProps) =>
    btnSize === "very-small"
      ? "14px"
      : btnSize === "small"
      ? "16px"
      : btnSize === "medium"
      ? "16px"
      : "20px"};
  line-height: ${({ btnSize }: IProps) =>
    btnSize === "very-small"
      ? "21px"
      : btnSize === "small"
      ? "24px"
      : btnSize === "medium"
      ? "24px"
      : "27px"};

  /* border: 0; */
  box-shadow: none;
  height: fit-content;
  width: fit-content;

  &:hover {
    border-color: black;
    color: ${({ btntype }: IProps) =>
      btntype !== "primary"
        ? btntype === "secondary"
          ? "white"
          : THEME.colors.primary
        : THEME.colors.brand};
    background-color: ${({ btntype }: IProps) =>
      btntype === "neutral"
        ? THEME.colors.brand
        : btntype === "secondary"
        ? "black"
        : "black"};
  }
  & :active,
  :focus {
    color: ${({ btntype }: IProps) =>
      btntype !== "primary"
        ? btntype === "secondary"
          ? "white"
          : THEME.colors.primary
        : THEME.colors.brand};
    background-color: ${({ btntype }: IProps) =>
      btntype === "neutral"
        ? THEME.colors.brand
        : btntype === "secondary"
        ? "black"
        : "black"};
  }

  /* @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: '24px';
    padding: 12px;
    min-width: 240px;
  } */
`;

export default Button;
