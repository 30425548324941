import { Steps } from "antd";
import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { deviceWidth, THEME } from "../../../config/theme";
import { Link } from "react-router-dom";

const stepItems = [
  {
    title: "MINT",
    description: (
      <p>
        Head to the{" "}
        <Link to="/ccfc-mint" style={{ color: THEME.colors.brand }}>
          mint page
        </Link>{" "}
        and connect your wallet to mint"
      </p>
    ),
  },
  {
    title: "REVEAL",
    description:
      "Connect your wallet to any marketplace and view your Cool Cats FC NFTs",
  },
  {
    title: "TRADE",
    description:
      "Buy, sell or trade the NFT representing the team you believe is going to win",
  },
  {
    title: "PLAY",
    description: "Participate in community events for a chance to win rewards",
  },
  {
    title: "EVOLVE",
    description:
      "As your team advances in the tournament, so will your NFT - unlocking eligibility for more rewards",
  },
];

export default function HoldersJourney() {
  const isResponsive = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <SectionWrapper>
      <div className="holders-header">
        <h2>Holders Journey</h2>
      </div>
      <div className="steps">
        <Steps
          labelPlacement="vertical"
          direction={isResponsive ? "vertical" : "horizontal"}
          items={stepItems}
        />
      </div>
    </SectionWrapper>
  );
}

const SectionWrapper = styled.div`
  width: 100%;

  & .holders-header {
    @media screen and (max-width: ${deviceWidth.tablet}) {
      text-align: center;
    }
  }

  & .steps {
    color: white;
    margin-top: 32px;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      padding: 0px 55px;
    }

    @media screen and (max-width: ${deviceWidth.mobile768}) {
      padding: 0px 8px;
    }
  }

  .ant-steps-item-tail {
    /* bac: #13fa56 !important; */
    @media screen and (max-width: ${deviceWidth.tablet}) {
      margin-top: 22px;
    }
  }

  .ant-steps-item-icon {
    background: #13fa56 !important;
    border-color: #13fa56 !important;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      margin-top: 22px;
    }
  }

  .ant-steps-icon {
    color: black !important;
  }

  .ant-steps-item-content {
    .ant-steps-item-title {
      font-family: "Fixture" !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 48px !important;
      line-height: 58px !important;
      color: #13fa56 !important;
    }

    .ant-steps-item-description {
      color: white !important;
      font-family: "Poppins" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 21px !important;
    }
  }
`;
