import styled from "styled-components";
import HomeLowerBanner from "../../components/organisms/HomeLowerBanner";
import HomePageInfoSection from "../../components/organisms/HomePageInfoSection";
import HomePageTopSection from "../../components/organisms/HomePageTopSection";
import { deviceWidth } from "../../config";

export default function HomePage() {
  return (
    <HomePageContainer>
      <HomePageTopSection />
      <HomePageInfoSection />
      <HomeLowerBanner />
    </HomePageContainer>
  );
}

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* margin: 226px auto 180px auto; */
  /* gap: 128px; */
  /* max-width: 1505px; */

  /* @media screen and (max-width: ${deviceWidth.bp1920}) {
    margin: 226px auto 180px auto;
    gap: 128px;
    max-width: 1505px;
  }

  @media screen and (max-width: ${deviceWidth.bp1728}) {
    margin: 226px auto 180px auto;
    gap: 120px;
    max-width: 1456px;
  }

  @media screen and (max-width: ${deviceWidth.bp1536}) {
    margin: 218px auto 160px auto;
    gap: 112px;
    max-width: 1286px;
  }

  @media screen and (max-width: ${deviceWidth.bp1366}) {
    margin: 202px auto 160px auto;
    gap: 104px;
    max-width: 1200px;
  }

  @media screen and (max-width: ${deviceWidth.bp1280}) {
    margin: 188px auto 160px auto;
    gap: 96px;
    max-width: 912px;
  }

  @media screen and (max-width: ${deviceWidth.bp1024}) {
    margin: 172px auto 120px auto;
    gap: 80px;
    max-width: 648px;
  }

  @media screen and (max-width: ${deviceWidth.bp768}) {
    margin: 140px auto 120px auto;
    gap: 72px;
    max-width: 400px;
  }

  @media screen and (max-width: ${deviceWidth.bp480}) {
    margin: 140px auto 120px auto;
    gap: 64px;
    max-width: 400px;
  }

  @media screen and (max-width: ${deviceWidth.bp440}) {
    margin: 140px auto 120px auto;
    gap: 64px;
    max-width: 312px;
  } */
`;
