import { Link } from "react-router-dom";
import styled from "styled-components";
import crown from "../../../assets/crown.png";
import { deviceWidth, THEME } from "../../../config/theme";

export default function Rewards() {
  return (
    <SectionWrapper>
      <div className="rewards-header">
        <h2>Rewards</h2>
        <p className="content-top">
          Exclusive rewards from top professional football players and teams.
          Most rewards will be delivered in the form of ERC-1155 and can be
          claimed on our website by January 15, 2023. Read the{" "}
          <Link
            to="/terms"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: THEME.colors.brand }}
          >
            General Rules and Terms and Conditions{" "}
          </Link>
          for more details.
        </p>
      </div>

      <div className="rewards-container">
        <div>
          <p className="header">IRL EXPERIENCES</p>
          <p className="content">
            <ul>
              <li>
                Serie A Top Teams Match Tickets (AC Milan, Juventus and Napoli)​
              </li>
            </ul>
          </p>
          <p className="header">IRL MERCHANDISE</p>
          <p className="content">
            <ul>
              <li>Signed and Match-worn Jerseys</li>
              <li>OneFootball x Cool Cats Special Edition Jerseys</li>
            </ul>
          </p>
          <p className="header">COLLECTIBLES</p>
          <p className="content">
            <ul>
              <li>Cool Cats NFTs</li>
              <li>Cool Pets NFTs</li>
              <li>Cats FC NFTs</li>
              <li>OneFootball Digital Video Moments (DVM) Packs</li>
            </ul>
          </p>
        </div>

        <div>
          <div className="grand-prize">
            <img loading="lazy" srcSet={crown + " 2x"} alt="crown"></img>

            <p className="header">GRAND PRIZE</p>
          </div>
          <p className="content-title">
            5 lucky holders of the championship team will get this
            once-in-a-lifetime multi-day experience
          </p>
          <p className="content">
            <ul>
              <li>
                Meet one of the world’s best football players IRL and ask all
                your burning questions
              </li>

              <li>
                Private tour of San Siro Stadium of AC Milan and the AC Milan
                museum
              </li>
              <li>VIP football match experience at AC Milan</li>
              <li>
                Meet the creators of “Cool Cats FC” and immerse yourself in the
                world of football and web3
              </li>
              {/* <li>Get an exclusive tour of the AC Milan Club Museum</li> */}
              <li>
                Roundtrip flight and accommodation at a four star hotel in Milan
                included
              </li>
              <li>... and more</li>
            </ul>
          </p>
          <br></br>
          <span>
            Find out more details of the rewards{" "}
            <a
              href={"https://content.coolcatsnft.com/ccfc-rewards.pdf"}
              rel="noopener noreferrer"
              target="_blank"
              className="nav-link"
            >
              here
            </a>{" "}
          </span>
        </div>
      </div>
    </SectionWrapper>
  );
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${deviceWidth.tablet}) {
    padding: 0px 55px;
  }

  @media screen and (max-width: ${deviceWidth.mobile768}) {
    padding-left: 0px 20px;
  }

  @media screen and (max-width: ${deviceWidth.mobile768}) {
    padding: 0px;
  }

  & .rewards-header {
    @media screen and (max-width: ${deviceWidth.tablet}) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;
    }
  }

  & .nav-link {
    color: #13fa56;
  }

  & .grand-prize {
    text-align: center;

    display: inline-block;
  }

  & .content-top {
    font-size: 14px;
    line-height: 21px;
  }

  & .rewards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 68px;
    gap: 66px;

    @media screen and (max-width: ${deviceWidth.tablet}) {
      flex-direction: column;
      /* align-items: center; */
      gap: 20px;

      /* text-align: center; */
    }

    @media screen and (max-width: ${deviceWidth.mobile500}) {
      padding-left: 0px;
      padding-right: 0px;
    }

    & .header {
      font-family: "Fixture";
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 58px;

      color: #13fa56;
    }
  }

  & .infoText {
    padding: 0px 0px 0px 55px;
    @media screen and (max-width: ${deviceWidth.tablet}) {
      margin: 0px;
      padding: 16px 55px;
    }
  }

  & .content {
    color: white;
    padding: 22px;
    font-size: 14px;
    line-height: 21px;
    ul {
      padding: 0px;
    }

    li {
    }
  }

  & .content-title {
    font-weight: 800;
    margin-top: 12px;
  }
`;
