import styled from "styled-components";
import { THEME, deviceWidth } from "../../../config";
import { HashLink as Link } from "react-router-hash-link";
import { LitePaperLink, socialContentData } from "../../../constants";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { MetaMaskContext } from "../../../hoc/Metamask";
import { Button } from "../../atoms/Button";
import { Popconfirm, theme } from "antd";
import { limitCharacter } from "../../../helpers/limitCharacter";

export const NavBar = () => {
  const location = useLocation();
  const isMintPage =
    location.pathname.includes("mint") || location.pathname.includes("terms");
  const { connect, isActive, account, disconnect } =
    useContext(MetaMaskContext);

  return isMintPage ? (
    <NavbarWrapper className={"nav-wra"}>
      <Link to="/" className="nav-link">
        Home
      </Link>

      {socialContentData.map(({ Icon, title, link }, idx) => (
        <a
          key={`social_${idx}`}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={title}
        >
          <Icon className="icon-social" />
        </a>
      ))}

      {isActive ? (
        // <span className='main-link'>
        <Popconfirm
          overlayClassName={"wallet-disconnect-popover"}
          okText={"Yes"}
          className="popover"
          onConfirm={disconnect}
          title={"Disconnect Wallet?"}
        >
          <Button
            btntext={`${limitCharacter(account || "", 5, true)}`}
            className="btn-style btn-style-success"
            btntype="neutral"
            btnSize="very-small"
          />
        </Popconfirm>
      ) : (
        // </span>

        <Button
          btntext="CONNECT WALLET"
          className="btn-style"
          onClick={() => {
            connect && connect();
          }}
          btntype="primary"
          btnSize="very-small"
        />
      )}
    </NavbarWrapper>
  ) : (
    <NavbarWrapper className={"nav-wra"}>
      <Link to="#about" className="nav-link" smooth>
        ABOUT
      </Link>
      <Link to="#howItWorks" className="nav-link" smooth>
        HOW IT WORKS
      </Link>
      <Link to="#rewards" className="nav-link" smooth>
        REWARDS
      </Link>
      <p>
        <a
          href={LitePaperLink}
          rel="noopener noreferrer"
          target="_blank"
          className="nav-link"
        >
          LITEPAPER
        </a>
      </p>

      {socialContentData.map(({ Icon, title, link }, idx) => (
        <a
          key={`social_${idx}`}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={title}
        >
          <Icon className="icon-social" />
        </a>
      ))}
    </NavbarWrapper>
  );
};

const LButton = styled(Button)`
  ${THEME.typography.label4}
  color: ${THEME.colors.primary};
  border-radius: 100px;
  height: 59px;
  width: 100%;
  margin-top: 32px;
`;

const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 14px;
  max-width: 1504px;
  position: absolute;
  height: 58px;
  width: 940px;
  top: 42px;
  left: calc(50% - 940px / 2);

  & .nav-link {
    padding: 18px;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;

    :hover {
      color: ${THEME.colors.brand};
    }
  }

  & .popover {
    .ant-popover-message .ant-popover-buttons {
      font-family: "Poppins" !important;
    }
  }

  @media screen and (max-width: ${deviceWidth.bp1920}) {
    max-width: 940px;
    left: calc(50% - 940px / 2);
  }

  @media screen and (max-width: ${deviceWidth.bp1728}) {
    width: 940px;
    left: calc(50% - 940px / 2);
  }

  @media screen and (max-width: ${deviceWidth.bp1536}) {
    width: 940px;
    left: calc(50% - 940px / 2);
  }

  @media screen and (max-width: ${deviceWidth.bp1366}) {
    width: 856px;
    top: 42px;
    left: calc(50% - 856px / 2);
  }

  @media screen and (max-width: ${deviceWidth.bp1280}) {
    width: 856px;
    top: 42px;
    left: calc(50% - 856px / 2);
  }

  @media screen and (max-width: ${deviceWidth.bp1024}) {
    width: 648px;
    top: 42px;
    left: calc(50% - 648px / 2);
    visibility: hidden;
  }

  @media screen and (max-width: ${deviceWidth.bp768}) {
    width: 400px;
    top: 42px;
    left: calc(50% - 400px / 2);
    visibility: hidden;
  }

  @media screen and (max-width: ${deviceWidth.bp480}) {
    width: 400px;
    top: 42px;
    left: calc(50% - 400px / 2);
    visibility: hidden;
  }

  @media screen and (max-width: ${deviceWidth.bp440}) {
    width: 312px;
    top: 42px;
    left: calc(50% - 312px / 2);
    visibility: hidden;
  }

  & .icon-social {
    display: block;
    margin: auto;
    margin-left: 22px;
    margin-right: 10px;

    :hover {
      color: ${THEME.colors.brand};
    }
  }

  & .btn-style {
    margin-left: 22px;
  }
`;
