import { createGlobalStyle } from "styled-components";
import { THEME } from "../config";

export const GlobalStyles = createGlobalStyle`

  html{
    scroll-behavior: smooth;
  }

  body {
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    overflow-x: hidden;
  }

  #root{
    width: 100%;
    overflow-x: hidden;
  }
  


  p, h1, h2, h3, h4,h5 {
    margin-bottom: 0;
  }

  button { 
    cursor: pointer;
  }

  ul, li {
    
    margin: 0px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* a:hover {
    color: #13fa56;
  }

  /* selected link */
  a:active {
    color: #13fa56;
  } */



  & .social-icons-popover{
    & .ant-popover-inner-content {
      padding: 0px !important;
    }
  }

  .Opensea {
    svg {
      width: 32px;
      height: auto;
    }
  }

  & .icon-social{
    pointer-events: bounding-box;
  }

  & .icon-social path:hover {
      fill: ${THEME.colors.brand} !important;
    }

  & .select-dropdown-class{
    background: ${THEME.colors.primary};
    box-shadow: 0px 2px 20px rgba(30, 44, 106, 0.08);
    border-radius: 8px;
    padding: 8px;
    [aria-selected="true"],[aria-selected="false"]{
      margin-bottom: 4px;
      border-radius: 8px;

      :last-child {
        margin-bottom: 0;
      }

      :hover{
        background: ${THEME.colors.interactiveSurfacePrimary.hover};  
      }
    }
    [aria-selected="true"]{
      background: ${THEME.colors.interactiveSurfacePrimary.active};
    }
  }

  .ant-notification-notice-error  {
    background: #FEE1E7;
  }

  .ant-notification-notice  {
    border-radius: 100px;
    padding: 16px;

    & .ant-notification-notice-message  {
      margin-bottom: 0 !important;
    }
  }


  & .wallet-disconnect-popover  {
    & .ant-popover-inner  {
      border-radius: 8px;
      box-shadow: 0px 2px 20px rgba(30, 44, 106, 0.08);
    }

    & .ant-popover-inner-content  {
      width: 173px;

      & .ant-popover-message {
        padding-top: 0px;
        > .anticon {
          display: none;
        }

        > .ant-popover-message-title  {
          ${THEME.typography.label1}
          padding: 0;
          text-align: left;
        }
      }

      & .ant-popover-buttons  {
        & button {
          border-radius: 100px;
          padding: 6px 16px;
          height: auto;
          border: 0;
          margin: 0 12px 0 0;

          ${THEME.typography.label1}

          :first-child {
            color: ${THEME.colors.contentPrimary};
            background: ${THEME.colors.interactive.surface.inverse.secondary.default};

            :hover  {
              background: ${THEME.colors.interactive.surface.inverse.secondary.hover};
            }

            :active {
              background: ${THEME.colors.interactive.surface.inverse.secondary.active};
            }
          }

          :nth-child(2) {
            color: ${THEME.colors.contentPrimary};
            margin: 0;
            background: ${THEME.colors.interactive.surface.brand.secondary.default};

            :hover  {
              background: ${THEME.colors.interactive.surface.brand.secondary.hover};
            }

            :active {
              background: ${THEME.colors.interactive.surface.brand.secondary.active};
            }
          }
        }
      }

    }
  }
`;
