import React from "react";
import styled from "styled-components";
import { deviceWidth, globalPadding } from "../../../config";
import bg from "../../../assets/backgroundBanner.png";
import { Button } from "antd";
import { LitePaperLink } from "../../../constants";

export default function HomeLowerBanner() {
  return (
    <SectionWrapper>
      <p>MORE INFORMATION CAN BE FOUND ON OUR LITEPAPER</p>
      <Button
        size="large"
        ghost
        className="learn-more"
        href={LitePaperLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        LEARN MORE
      </Button>
    </SectionWrapper>
  );
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background: #13fa56;

  @media screen and (max-width: ${deviceWidth.tablet}) {
    display: flex;
    flex-direction: column;
  }

  p {
    font-family: "Fixture";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 98%;
  }

  background-image: url(${bg});
  width: 100%;
  /* background-size: cover;  */
  background-repeat: no-repeat;
  background-position: 0% -40%;
  background-size: 100% 90%;
  padding-top: 126px;
  padding-bottom: 126px;
  padding-left: ${globalPadding.large};
  padding-right: ${globalPadding.large};

  & .learn-more {
    color: black;
    border-color: black;
    font-weight: bold;
    font-family: "Poppins";

    :hover {
      color: #13fa56;
      border-color: #13fa56;
      background-color: black;
    }

    :focus {
      color: black;
      border-color: black;
      background-color: #13fa56;
    }
  }

  @media screen and (max-width: ${deviceWidth.desktopMd}) {
    padding-left: ${globalPadding.medium};
    padding-right: ${globalPadding.medium};
  }

  @media screen and (max-width: ${deviceWidth.desktopXs}) {
    padding-left: ${globalPadding.small};
    padding-right: ${globalPadding.small};
  }

  @media screen and (max-width: ${deviceWidth.tabletXl}) {
    padding-left: ${globalPadding.xsmall};
    padding-right: ${globalPadding.xsmall};
  }

  @media screen and (max-width: ${deviceWidth.tablet}) {
    padding-left: ${globalPadding.mobile};
    padding-right: ${globalPadding.mobile};
    text-align: center;
  }
`;
